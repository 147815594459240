import React, { useEffect, useState } from "react";
import { usePageContext } from "../../Context/PageContextProvider";
import { IconButton, Link, Typography } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { useStyles } from "./Login.style";
export default function Login(props) {
    const { startPageData } = usePageContext();
    const classes = useStyles({ loginIcon: startPageData.loginIcon.value?.url, });
    const [showDualLoginBlock, setShowDualLoginBlock] = useState(false);
    useEffect(() => {
        const addListeners = () => {
            window.addEventListener("keydown", closeOnEscape);
            window.addEventListener("click", clickOutside);
        };
        const removeListeners = () => {
            window.removeEventListener("keydown", closeOnEscape);
            window.removeEventListener("click", clickOutside);
            setShowDualLoginBlock(false);
        };
        const closeOnEscape = (event) => {
            event.key === "Escape" && removeListeners();
        };
        const clickOutside = (event) => {
            !event.target.closest("#loginBlock") && removeListeners();
        };
        showDualLoginBlock ? addListeners() : removeListeners();
    }, [showDualLoginBlock]);
    if (props.hidden)
        return null;
    if (!startPageData.headerBlock.loginLink?.value &&
        !startPageData.headerBlock.loginLinkPrivate?.value &&
        !startPageData.headerBlock.loginLinkBusiness?.value)
        return null;
    const singleLogin = !startPageData.headerBlock.loginLinkPrivate?.value || !startPageData.headerBlock.loginLinkBusiness?.value;
    const loginIcon = startPageData.loginIcon?.value?.url
        ? React.createElement("div", { className: classes.loginIcon })
        : React.createElement(PersonIcon, { color: "primary" });
    const renderLogin = () => {
        return (React.createElement(IconButton, { color: "inherit", href: singleLogin ? startPageData.headerBlock.loginLink?.value : undefined, onClick: singleLogin ? undefined : () => { setShowDualLoginBlock(!showDualLoginBlock); }, edge: false, className: classes.loginButton },
            loginIcon,
            React.createElement(Typography, { variant: "body1", component: "p", className: classes.loginText }, startPageData.headerBlock.loginText?.value)));
    };
    const renderDualLoginBlock = () => {
        if (!showDualLoginBlock)
            return null;
        return (React.createElement("div", { id: "loginBlock", className: classes.loginBlock },
            React.createElement(Link, { href: startPageData.headerBlock.loginLinkPrivate?.value }, startPageData.headerBlock.loginTextPrivate?.value),
            React.createElement(Link, { href: startPageData.headerBlock.loginLinkBusiness?.value }, startPageData.headerBlock.loginTextBusiness?.value)));
    };
    return (React.createElement(React.Fragment, null,
        renderLogin(),
        renderDualLoginBlock()));
}
