import { makeStyles, createStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => createStyles({
    loginIcon: (props) => ({
        backgroundImage: `url(${props.loginIcon})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        backgroundPositionY: "center",
        height: 24,
        width: 24,
        marginRight: 8
    }),
    loginButton: {
        padding: 0
    },
    loginText: {
        ...theme.customStyling?.Header?.loginText,
    },
    loginBlock: {
        backgroundColor: theme.customStyling?.Header?.headerBlock?.backgroundColor || theme?.header?.backgroundColor || "white",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        width: 220,
        top: 146,
        border: `1px solid ${theme.colorCard?.dividerLine || theme.navigation?.divider}`,
        ...theme.customStyling?.Header?.loginBlock?.block,
        "& .MuiLink-underlineHover": {
            color: theme.customStyling?.Header?.headerBlock?.color || theme.colorCard?.inputText || theme.palette?.text?.primary,
            background: "transparent",
            padding: "12px 24px",
            fontWeight: 100,
            "&:hover, :active": {
                background: theme.contentBox?.backgroundColor,
                textDecoration: "none",
            },
            ...theme.customStyling?.Header?.loginBlock?.link,
        },
    }
}));
